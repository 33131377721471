import React, { useEffect, useState } from "react";
import LoadingStatus from "../../components/LoadingStatus";

import ReportFilter from "../../components/ReportFilter";
import ReportList from "../../components/ReportList";
import { useDocuments } from "../../hooks/documentsHook";
import useFetchAllTickers from "../../hooks/tickersHook";

export default function Equities() {
  const { tickers: tickersData, isLoading: isLoadingTickers } =
    useFetchAllTickers();

  const tickers = tickersData?.map((ticker) => ticker.attributes) || [];

  const {
    isLoadingInitial,
    error: errorLoadingDocuments,
    documents,
    selectedTickers,
    setSelectedTickersWithReset,
    selectedPeriodFilter,
    setSelectedPeriodFilterWithReset,
    selectedSort,
    setSelectedSortWithReset,
    hasMore,
    loadMoreDocuments,
    isLoadingMore,
  } = useDocuments();

  let reportListTemplate = <LoadingStatus loadingType="reports_loading" />;
  if (isLoadingInitial) {
    reportListTemplate = <LoadingStatus loadingType="reports_loading" />;
  } else if (errorLoadingDocuments) {
    reportListTemplate = <LoadingStatus loadingType="reports_error" />;
  } else if (documents.length === 0) {
    reportListTemplate = <LoadingStatus loadingType="reports_empty" />;
  } else {
    reportListTemplate = (
      <ReportList
        reports={documents}
        hasMore={hasMore}
        loadMore={loadMoreDocuments}
        isLoadingMore={isLoadingMore}
      />
    );
  }

  return (
    <>
      <ReportFilter
        tickers={tickers}
        selectedTickers={selectedTickers}
        setSelectedTickers={setSelectedTickersWithReset}
        selectedPeriodFilter={selectedPeriodFilter}
        setSelectedPeriodFilter={setSelectedPeriodFilterWithReset}
        selectedSort={selectedSort}
        setSelectedSort={setSelectedSortWithReset}
        loadingTickers={isLoadingTickers}
      />
      {reportListTemplate}
    </>
  );
}

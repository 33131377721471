import { useCallback, useEffect, useMemo, useState } from "react";

import {
  PeriodKey,
  SortingMethod,
  getPeriodStartDate,
  getSortingParams,
} from "../../../utils";
import { AxiosResponse } from "axios";
import { fetchDataFromCMS } from "../../../cms-api";
import { useAuthContext } from "../../../contexts/authContext";

const PAGE_SIZE = 36;

export const useDocuments = () => {
  const [isLoadingInitial, setIsLoadingInitial] = useState<boolean>(true);
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const [selectedSort, setSelectedSort] = useState("featured");
  const [loadingError, setError] = useState<AxiosResponse<any, any> | null>(
    null,
  );

  const { accessToken, impersonation } = useAuthContext();

  const queryParams = useMemo(() => {
    const sortingParams = () => {
      if (selectedSort == "company_name")
        return ["company_name:asc", "report_date:desc"];
      else if (selectedSort == "report_date")
        return ["report_date:desc", "company_name:asc"];
      return [
        "company.is_featured:desc",
        "company_name:asc",
        "report_date:desc",
      ];
    };

    const params: Record<string, any> = {
      sort: sortingParams(),
      fields: [
        "company_name",
        "summary",
        "report_start_date",
        "report_date",
        "body",
      ],
      filters: {},
      pagination: {
        pageSize: PAGE_SIZE,
        page: currentPage,
      },
      populate: ["company"],
    };
    if (selectedCompanies.length) {
      params.filters["company_name"] = {
        $eq: selectedCompanies.map((company) => company),
      };
    }

    return params;
  }, [currentPage, selectedCompanies, selectedSort]);

  const fetchDocuments = useCallback(async () => {
    try {
      const { data, error } = await fetchDataFromCMS(
        "/api/transcript-privates",
        accessToken,
        queryParams,
        {
          method: "get",
        },
        impersonation,
      );

      if (error) {
        throw error;
      }

      if (data) {
        if (currentPage === 1) {
          setDocuments(data.data);
        } else {
          setDocuments((prevDocuments) => [...prevDocuments, ...data.data]);
        }
        setHasMore(data.meta.pagination.pageCount > currentPage);
      }
    } catch (fetchError: any) {
      setError(fetchError);
    } finally {
      setIsLoadingInitial(false);
      setIsLoadingMore(false);
    }
  }, [accessToken, currentPage, selectedCompanies, selectedSort]);

  // Effect to handle initial load and reload when filters change
  useEffect(() => {
    if (isLoadingInitial && accessToken) {
      fetchDocuments();
    }
  }, [isLoadingInitial, fetchDocuments, accessToken]);

  // Effect to handle loading more documents
  useEffect(() => {
    if (isLoadingMore && hasMore && accessToken) {
      fetchDocuments();
    }
  }, [isLoadingMore, hasMore, fetchDocuments, accessToken]);

  const loadMoreDocuments = useCallback(() => {
    if (!hasMore || isLoadingMore) return;
    setIsLoadingMore(true);
    setCurrentPage((prevPage) => prevPage + 1);
  }, [hasMore, isLoadingMore]);

  const resetAndFetch = useCallback(() => {
    setIsLoadingInitial(true);
    setDocuments([]);
    setCurrentPage(1);
    setHasMore(true);
  }, []);

  const setSelectedCompaniesWithReset = useCallback(
    (companies) => {
      setSelectedCompanies(companies);
      resetAndFetch();
    },
    [resetAndFetch],
  );

  const setSelectedSortWithReset = useCallback(
    (sort) => {
      setSelectedSort(sort);
      resetAndFetch();
    },
    [resetAndFetch],
  );

  return {
    documents,
    isLoadingInitial,
    loadingError,
    selectedCompanies,
    setSelectedCompaniesWithReset,
    selectedSort,
    setSelectedSortWithReset,
    hasMore,
    isLoadingMore,
    loadMoreDocuments,
  };
};

import React, { useEffect, useState } from "react";
import BannerComponent from "../../components/Banner";
import EquitiesResearch from "../../components/CompanyResearchEquities";
import PrivatesResearch from "../../components/CompanyResearchPrivates";

import NorthstarPageLayout from "../../components/NorthstarPageLayout";
import { useAuthContext } from "../../contexts/authContext";
import { useDocuments } from "../../hooks/documentsHook";
import { SCOPES } from "../../constants";
import { navigate } from "gatsby";

enum Tab {
  Equities = "equities",
  Privates = "privates",
}

const CompanyResearch: React.FC = () => {
  const urlParams = new URLSearchParams(
    typeof window === "undefined" ? "" : window.location.search,
  );
  const pageTab = urlParams.get("tab");

  const { accessToken, clearIdentity } = useAuthContext();

  const [tab, setTab] = useState(pageTab ?? Tab.Equities);

  const { error: errorLoadingDocuments } = useDocuments();

  const updateTab = (tab: Tab) => {
    setTab(tab);
    navigate(`/company_research?tab=${tab}`);
  };

  useEffect(() => {
    let ignore = true;
    if (errorLoadingDocuments?.status === 401 && !ignore && clearIdentity) {
      clearIdentity();
    }
    return () => {
      ignore = false;
    };
  }, [errorLoadingDocuments?.status]);

  return (
    <NorthstarPageLayout
      title="Company Research"
      className="overflow-y-scroll"
      requiredScope={SCOPES.COMPANY_RESEARCH}
    >
      <>
        {/* <Spinner active={isLoading} /> */}
        <BannerComponent
          title="Company Research"
          description="Welcome to BAM's AI-enabled research platform created exclusively for our portfolio companies. This tool leverages AI and our research library to generate robust company research."
        />
        <div className="flex mt-12 font-roboto border-b border-b-gray-200">
          <div
            className={`pr-3 pb-2 hover:text-black font-medium hover:text-bold cursor-pointer ${
              tab == Tab.Equities
                ? "text-black border-b-2 border-b-gray-200"
                : "text-gray-400"
            }`}
            onClick={() => updateTab(Tab.Equities)}
          >
            Equities
          </div>
          <div
            className={`px-3 pb-2 hover:text-black font-medium hover:text-bold cursor-pointer ${
              tab == Tab.Privates
                ? "text-black border-b-2 border-b-gray-200"
                : "text-gray-400"
            }`}
            onClick={() => updateTab(Tab.Privates)}
          >
            Privates
          </div>
        </div>
        {tab == Tab.Equities && <EquitiesResearch />}
        {tab == Tab.Privates && <PrivatesResearch />}
      </>
    </NorthstarPageLayout>
  );
};

export default CompanyResearch;
